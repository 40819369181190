<!-- @TODO Artem: Styles like on mockup -->
<div *ngIf="visible"
     [ngClass]="'p-confirm-popup p-component'"
     [ngStyle]="style"
     [class]="styleClass"
     (click)="onOverlayClick($event)"
     [@animation]="{ value: 'open', params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions } }"
     (@animation.start)="onAnimationStart($event)"
     (@animation.done)="onAnimationEnd($event)">

  <div #content class="p-confirm-popup-content">
    <span class="p-confirm-popup-message" [innerHTML]="confirmation.message"></span>
  </div>

  <div class="p-confirm-popup-footer">
    <button *ngIf="confirmation.rejectVisible !== false"
            type="button"
            class="btn basic"
            (click)="reject()">
      {{rejectButtonLabel}}
    </button>

    <button *ngIf="confirmation.acceptVisible !== false"
            type="button"
            class="btn primary"
            (click)="accept()">
      {{acceptButtonLabel}}
    </button>
  </div>
</div>
